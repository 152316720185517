export const errorMessages = {
	1: "Usuario y/o contraseña no introducidos",
	2: "Usuario y contraseña no correctos",
	3: "Falta el parámetro “xxxx”",
	4: "El parámetro “xxxx” no es válido",
	5: "La fecha tiene que ser mayor a hoy",
	6: "No se encuentran los datos de conexión",
	7: "La hora tienen que ser mayor a la actual",
	8: "Póngase en contacto con la clínica",
	20: "ERROR CTD",
	21: "CLÍNICA CERRADA",
	22: "CITA QUIRÓFANO",
	23: "EL MÉDICO NO EXISTE",
	24: "LA AGENDA DEL MÉDICO ESTÁ BLOQUEADA. INTÉNTELO MÁS TARDE",
	25: "NO EXISTE EL PROCESO",
	26: "NO HAY SALAS LIBRES",
	27: "LA CITA NO ESTÁ DISPONIBLE",
	28: "NO HA SIDO POSIBLE BUSCAR EL CÓDIGO DEL PACIENTE",
	30: "LA CITA NO EXISTE",
	31: "NO ES POSIBLE CANCELAR CITAS DE PACIENTES RESERVADOS",
	32: "NO ES POSIBLE CANCELAR CITAS DE QUIRÓFANO",
	33: "NO ES POSIBLE CANCELAR CITAS RESERVADAS",
	34: "LA CITA TIENE ERRORES (NOMBREHIS||FECHA), NO ESTÁ LLENA",
	35: "LA AGENDA ESTÁ BLOQUEADA. INTÉNTELO MÁS TARDE",
	36: "LA CITA YA HA SIDO RECEPCIONADA",
	37: "EL DNI NO ESTÁ DADO DE ALTA",
	38: "NO ES POSIBLE MODIFICAR CITAS RESERVADAS",
	39: "LA CITA ANTERIOR NO EXISTE",
	40: "EL PACIENTE NO EXISTE EN LA CLÍNICA",
	41: "LA CLÍNICA NO ES VISIBLE",
	42: "EL MÉDICO NO ES VISIBLE",
	99: "“Errores de programación”",
};
